<template>
  <TVModal
    hidden-button-close
    class="tour-finished__modal"
  >
    <div
      slot="body"
      class="tour-finished__modal__body"
    >
      <div class="tour-finished__modal__illustration">
        <img
          src="~@/assets/tour-finished-illustration.svg"
        >
      </div>
      <div class="tour-finished__modal__info">
        <h3>Uhuuuu! Parabéns!</h3>
        <p>Agora você está pronto para explorar ao máximo a sua SAS Educação TV</p>
        <TVButton
          size="big"
          @click="close()"
        >
          Voltar para o início
        </TVButton>
      </div>
    </div>
  </TVModal>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import TVModal from '@/components/global/TVModal'
import Confetti from '@/plugins/confetti'

export default {
  name: 'TourCompletedModal',
  components: {
    TVModal,
  },
  computed: {
    ...mapState('profile', ['personalData']),
    ...mapGetters(['onboarding']),
  },
  mounted() {
    if (this.$mq === 'x_large') {
      new Confetti({
        elm: document.querySelector('.tv-modal__overlay'),
        width: window.innerWidth,
        height: window.innerHeight,
        length: 120,
        duration: 8000
      })
    }
  },
  methods: {
    ...mapActions(['setOnboarding']),
    close() {
      this.$emit('close')
      this.setOnboarding({
        ...this.onboarding,
        users: {
          ...this.onboarding.users,
          [this.personalData.id]: {
            ...this.onboarding.users[this.personalData.id],
            id: this.personalData.id,
            welcomeActive: false,
            tourActive: false,
          },
        },
      })
    },
  },
}
</script>

<style lang="sass">
.tour-finished__modal
  .tv-modal
    +mq-l
      box-sizing: border-box
      +flex_center

  &__body
    text-align: center
    padding-bottom: 10px

  &__info
    padding-top: 20px

    h3
      font-size: 28px
      margin-bottom: 10px

    p
      color: $color-ink-light
      font-size: 16px
      margin-bottom: 20px
</style>
